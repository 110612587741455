import React from 'react';
import BlockchainConnectPageTemplate from '../../../components/Connect/Blockchain';

const subtitles = {
  A: (
    <>
      With the successful delivery of a number of Blockchain projects, Tintash has developed a range
      of expertise in Blockchain development and leverages the same product development proficiency
      that helped our startups, unicorns and fortune 500 clients to realize their dreams.
    </>
  ),
  B: 'Leverage our expert blockchain developers to elevate your project, delivering the dream outcomes you’ve always wanted.',
};
function index({location, pageContext}) {
  return (
    <BlockchainConnectPageTemplate
      variant={pageContext.variant}
      location={location}
      title="Blockchain Development Services"
      subtitles={subtitles}
      metaTitle="Blockchain Development Services - Tintash"
      metaDescription=" Tintash offers Blockchain Development Services you can rely on. We offer services from crypto integrations, rosetta implementation, & more across platforms!"
      pageviewTitle="Connect/Blockchain/Blockchain.Development"
    />
  );
}

export default index;
